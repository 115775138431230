import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import slugify from 'slugify'

import { BREAKPOINTS, ANIMATION } from '../../styles/cssVariables'
import { calculateDaysBetween } from '../../utils/utils'
import Tag from './tag'
import Button from './button'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'

import SvgBlogPostIcon from '../../images/icons/blog-post-icon.svg'
import SvgEbookIcon from '../../images/icons/ebook-icon.svg'
import SvgPodcastIcon from '../../images/icons/podcast-icon.svg'

const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid rgba(229, 234, 244, 0.5);
  border-radius: 4px;

  background-color: #fff;

  filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
  overflow: hidden;
`

const CardImgContainer = styled.div`
  position: relative;

  ${(props) =>
    !props.hasImage &&
    `
    padding-bottom: calc(9 / 16 * 100%);
    background-color: var(--color-skobeloff);
  `}
`

const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

const CardContent = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 38px 30px 38px 40px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 24px 22px 32px;
  }
`

const ContainerTags = styled.div`
  margin: 10px -3px 18px;
`

const TagCompressed = styled(Tag)`
  display: inline-block;
  margin: 3px !important;
  padding: 4px 8px 5px;
  line-height: 1.6;
`

const CardMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-style: italic;
  font-weight: 300;

  p {
    font-size: 14px;
    font-weight: 300;
    color: var(--color-body);
  }
`

const Excerpt = styled.p`
  margin-top: 12px;
  margin-bottom: 36px;
  color: var(--color-body);

  font-size: 14px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: auto;
`

const ButtonDownload = styled(Button)`
  height: fit-content;
  margin-left: 2.25em;

  svg {
    transform: rotate(90deg);
  }
`

const MainButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;

  background-color: var(--color-skobeloff);
  color: #fff !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`

const MainButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border: 1px solid var(--color-skobeloff);

  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
 
  background-color: var(--color-skobeloff);
  color: #fff !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

/**
 * Card to be rendered on the Guide Feed
 * Data is populated from a passed Guide Content data type in CMS
 */
const GuideCard = ({ content }) => {


  return (
    <Card>
      {!content.buttonDestination ? (
        <Link to={`/sustainability-framework/guide/${content.slug}`}>
          <CardImgContainer hasImage={Boolean(content.image)}>
            {content.image && (
              <GatsbyImage
                alt={content.image.title}
                image={content.image.gatsbyImageData}
              />
            )}
            <IconContainer>
              <SvgBlogPostIcon />
            </IconContainer>
          </CardImgContainer>
        </Link>
      ) : (
        <a href={content.buttonDestination}>
          <CardImgContainer hasImage={Boolean(content.image)}>
            {content.image && (
              <GatsbyImage
                alt={content.image.title}
                image={content.image.gatsbyImageData}
              />
            )}
            <IconContainer>
              <SvgBlogPostIcon />
            </IconContainer>
          </CardImgContainer>
        </a>
      )}

      <CardContent>
        {!content.buttonDestination ? (
          <Link to={`/sustainability-framework/guide/${content.slug}`}>
            <h3>{content.title}</h3>
          </Link>
        ) : (
          <a href={content.buttonDestination} rel="nofollow" target="_blank">
            <h4>{content.title}</h4>
          </a>
        )}

        <ContainerTags>
        
          {content?.pillar?.map((pillar) => (
            <TagCompressed key={pillar} type="impact">
              {pillar}
            </TagCompressed>
          ))}
        </ContainerTags>

        

        <Excerpt>{content.excerpt}</Excerpt>

        <ButtonContainer>
          {content.type !== 'Static' && !content.buttonDestination && (
            <MainButtonGatsbyLink to={`/sustainability-framework/guide/${content.slug}`}>
             
             Read post
              
            <Arrow /> 
            </MainButtonGatsbyLink>
          )}
          {content.buttonDestination && (
            <MainButton  href={content.buttonDestination}
            rel="nofollow"
            target="__blank">
      
               Read post
               
                <Arrow /> 
            </MainButton>
          )}

          {content.attachedFile && (
            <ButtonDownload type="link">
              <a
                href={content.attachedFile.file.url}
                rel="nofollow"
                target="__blank"
              >
                PDF
              </a>
            </ButtonDownload>
          )}
        </ButtonContainer>
      </CardContent>
    </Card>
  )
}

GuideCard.propTypes = {
  /** Hub Content data type in CMS */
  content: PropTypes.shape({
    /** Content title */
    title: PropTypes.string.isRequired,
    /** Content page slug, if this content has a page */
    slug: PropTypes.string,
    /** Marks a content type as premium (currently unused) */
    isPremium: PropTypes.bool,
    /** Content will appear in featured area on Guide page */
    isFeatured: PropTypes.bool,
    /** Content will appear in picked area on Guide page */
    isPicked: PropTypes.bool,
    /** Content card will render differently depending on marked type */
    
    /** Tags */
    pillar: PropTypes.arrayOf(PropTypes.string),
    /** Attribution */
    
    /** Content will appear ordered by most recent publication date */
    publishDate: PropTypes.string.isRequired,
    /** Content preview appearing on Guide page */
    excerpt: PropTypes.string,
    /** Appears in Guide feed and as a large banner on this content's page */
    image: PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
    /** Renders a download link if a file is provided */
    attachedFile: PropTypes.object,
    /** Custom destination for Guide feed button */
    buttonDestination: PropTypes.string,
  }),
}

export default GuideCard
