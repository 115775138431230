import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "styled-components"
import { ANIMATION, BREAKPOINTS, LAYOUT } from "../../styles/cssVariables"
import Container from "../layout/container"


export const HeroWrapper = styled.section`
padding-top: 80px;
padding-bottom: 80px;
@media (max-width: ${BREAKPOINTS.med}px) {
  padding-bottom: 50px;  
  padding-top: 50px;

}
@media (max-width: ${BREAKPOINTS.sml}px) {
  padding-bottom: 30px;
  padding-top: 30px;

}
  background-color: var(--color-skobeloff);

  color: #fff;

`

export const HeroContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-left: ${LAYOUT.gutterPage / 2}px;
    padding-right: ${LAYOUT.gutterPage / 2}px;
    > div {
      flex-direction: column
    }
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
      padding-left: ${LAYOUT.gutterPageMobile}px;
      padding-right: ${LAYOUT.gutterPageMobile}px;

  }
`

export const HeroPanel = styled.div`
  width: 100%;

  &:first-child {
    flex: 6;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      text-align: center;
    }
  }

  &:last-child {
    flex: 6;
  }

`

export const HeroTitle = styled.h1`
  margin-bottom: 25px;

  color: var(--color-white);
`

export const HeroImage = styled(GatsbyImage)`
  margin-left: auto;
  @media (max-width: ${BREAKPOINTS.med}px) {
    display: none;
  }
  

`

export const HighlightsContainer = styled.section`
  box-sizing: border-box;
  margin-top: 86px;
  display: flex;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${LAYOUT.gutterPage}px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
  
      flex-direction: column;
  
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 0;
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`

export const FeaturedContainer = styled.div`
  flex: 1;

  @media (min-width: ${BREAKPOINTS.tblUp}px) {
    margin-right: 5%;
  }


  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 50px;
  }
`

export const PickedContainer = styled.div`
  flex: 1;
  align-self: center;
  @media (min-width: ${BREAKPOINTS.medUp}px) {
    margin-left: 70px;
  }
`

export const PickedTitle = styled.h2`
  margin-bottom: 50px;
  font-size: 32px;
  line-height: 1.3;
  color: var(--color-heading);

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    font-size: 26px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    font-size: 22px;
    line-height: 1.35;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
  
    margin-top: 50px;
    align-self: flex-start;
    margin-bottom: 25px;

  }

`

export const FeaturedTitle = styled(PickedTitle)`
  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    display: none;
  }
`

export const PickedItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  opacity: 0.62;

  transition: opacity ${ANIMATION.speedMedium} ease;

  &::before {
    content: '';
    height: 100%;
    width: 4px;
    position: absolute;
    left: calc(-15px - 4px);
    background-color: var(--color-blue);
    opacity: 0;
    transition: opacity ${ANIMATION.speedMedium} ease;
  }

  &:not(:last-child) {
    margin-bottom: 38px;
  }

  &:hover,
  &:hover::before {
    opacity: 1;
  }

  > div a h3 {
    margin-right: 25px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    opacity: 1;
  }
  > div a h3 {
    font-size: 20px;
    line-height: 1.4;
    margin-right: 15px;
    color: var(--color-heading);
    @media (max-width: ${BREAKPOINTS.lrg}px) {
      font-size: 16px;
      line-height: 1.65;
    }
  }
`

export const PickedMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-style: italic;
  font-weight: 300;

  p {
    font-size: 14px;
    font-weight: 300;
  }
`
export const FilterWrapper = styled.div `
box-sizing: border-box;    
width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin: 0 auto;
  padding: 0 ${LAYOUT.gutterPage}px;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-left: ${LAYOUT.gutterPage / 2}px;
    padding-right: ${LAYOUT.gutterPage / 2}px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;
  }

`
export const FilterContainer = styled.div`
  margin-left: 0;
  margin-right: auto;
  
  width: 66%;
 

  @media (max-width: ${BREAKPOINTS.med}px) {
    width: 100%;
  }


`

export const FilterNav = styled.nav`
  display: flex;

  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    > * {
      flex: 1;

      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    flex-direction: column;

    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`
export const FilterContainerGuide = styled.div`
  margin-left: 0;
  margin-right: auto;
  
  width: 31.5%;
 

  @media (max-width: ${BREAKPOINTS.med}px) {
    width: 100%;
  }


`

export const FilterNavGuide = styled.nav`
  display: flex;

  // @media (min-width: ${BREAKPOINTS.smlUp}px) {
  //   > * {
  //     flex: 1;

  //     &:not(:first-child) {
  //       margin-left: 40px;
  //     }
  //   }
  // }

  
    flex-direction: column;

    > *:not(:first-child) {
      margin-top: 20px;
    }
  
`
export const GridContainer = styled.div`
  box-sizing: border-box;
  padding-top: 60px;
  margin-bottom: 250px;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${LAYOUT.gutterPage}px;
  padding-right: ${LAYOUT.gutterPage}px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px 30px;
  

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    
      grid-template-columns: 1fr 1fr;
      padding-left: ${LAYOUT.gutterPage / 2}px;
      padding-right: ${LAYOUT.gutterPage / 2}px;
  }




  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 200px;

    
    grid-template-columns: 1fr;
    gap: 20px;
    
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;

  }
`
export const GridContainerGuide = styled.div`
  box-sizing: border-box;
  padding-top: 60px;
  margin-bottom: 250px;
  padding-bottom: 125px;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${LAYOUT.gutterPage}px;
  padding-right: ${LAYOUT.gutterPage}px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px 30px;
  

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    
      grid-template-columns: 1fr 1fr;
      padding-left: ${LAYOUT.gutterPage / 2}px;
      padding-right: ${LAYOUT.gutterPage / 2}px;
  }




  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding-top: 40px;
    padding-bottom: 75px;
    margin-bottom: 200px;

    
    grid-template-columns: 1fr;
    gap: 20px;
    
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;

  }
`

export const PaginationContainer = styled(Container)`
  > div {
    justify-content: center;
  }
`

export const Pagination = styled.nav`
  padding-bottom: 200px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 150px;
  }
`

export const PaginationLink = styled(Link)`
  height: 34px;
  width: 34px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  font-size: 15px;
  color: var(--color-heading);

  ${(props) =>
    props.$isActive &&
    `
    background-color: var(--color-violet);
    color: #fff !important;
  `}
`
export const EmptyState = styled.div`
  text-align: center;
  > h3 {
    color: var(--color-body);
    margin-top: 64px;
  }
`
export const MainContainer = styled.section`
  min-height: 90vh;
  margin-top: 100px;
`
