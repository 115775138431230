import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from 'slugify'
import Select from 'react-select'
import { navigate } from '@reach/router'
import Hero from '../components/hero'
import { ANIMATION, BREAKPOINTS } from '../styles/cssVariables'
import { capitalise, calculateDaysBetween } from '../utils/utils'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import GuideCard from '../components/ui-kit/guideCard'

import SvgBlogPostIcon from '../images/icons/blog-post-icon.svg'
import SvgEbookIcon from '../images/icons/ebook-icon.svg'
import SvgPodcastIcon from '../images/icons/podcast-icon.svg'


import { customSelectStyle } from '../styles/selectStyle'
import { useGuide } from '../hooks/filters'
import { EmptyState, FilterWrapper, FeaturedContainer, FeaturedTitle, FilterContainerGuide, FilterNavGuide, GridContainerGuide, HighlightsContainer, MainContainer, PickedContainer, PickedItemContainer, PickedMetaContainer, PickedTitle } from '../components/education-hub/styled'

const GuidePage = ({
  data: {
    contentfulPageSustainabilityFrameworkGuide: { seoTitle, seoDescription, hero, openGraphImage },
    featuredContent,
    pickedContent,
    guideContentMeta,
    allContentfulGuideContent
  },
  location
}) => {

  const { guides, filters } = useGuide(
    allContentfulGuideContent,
    guideContentMeta
  )
  const [featured] = featuredContent.edges;
  const heroHeading = hero.heading;
  const heroSubheading = hero.subheading; 
  const heroCtaText = hero.ctaText;
  const heroCtaDestination = hero.ctaDestination; 

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}
      />

      <Hero
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />


      <HighlightsContainer>
        <FeaturedContainer>
          <FeaturedTitle>Featured</FeaturedTitle>
          <GuideCard content={featured.node} />
        </FeaturedContainer>

        <PickedContainer>
          <PickedTitle>More picks</PickedTitle>
          {pickedContent?.edges?.slice(0,3).map((item) => {
            // const daysBetween = calculateDaysBetween(
            //   new Date(),
            //   new Date(item.node.publishDate)
            // )

            return (
              <PickedItemContainer key={item.node.title}>
                <div>
                  <Link to={item.node.slug}>
                    <h3>{item.node.title}</h3>
                  </Link>
      
                </div>

                <div>
                  <SvgBlogPostIcon />
                </div>
              </PickedItemContainer>
            )
          })}
        </PickedContainer>
      </HighlightsContainer>


      <MainContainer>
        <FilterWrapper>
          <FilterContainerGuide>
              <FilterNavGuide>
                
                <Select
                  onChange={filters.pillarChangeCb}
                  options={filters.pillars}
                  styles={customSelectStyle}
                  value={filters.selectedPillar}
                />
              </FilterNavGuide>
            </FilterContainerGuide>
          </FilterWrapper>

          {guides.length > 0 ? (
            <GridContainerGuide width={12}>
              {guides?.map((guide) => {
                return (
                  <GuideCard key={guide.node.slug} content={guide.node} />
                )
              })}
            </GridContainerGuide>
          ) : (
            <EmptyState>
              <h3>No results</h3>
            </EmptyState>
          )}
      </MainContainer>
    </Layout>
  )
}

export default GuidePage;

export const guideContentQuery = graphql`
  query GuideContentQuery {
    contentfulPageSustainabilityFrameworkGuide {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
        
      }
    }

    featuredContent: allContentfulGuideContent(
      sort: { fields: [publishDate], order: DESC }
      filter: { isFeatured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          isPremium
          isFeatured
          pillar
          publishDate
          excerpt
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77777777778)
          }
          attachedFile {
            file {
              url
            }
          }
          buttonDestination
        }
      }
    }

    pickedContent: allContentfulGuideContent(
      sort: { fields: [publishDate], order: DESC }
      filter: { isPicked: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          isPremium
          isPicked
          publishDate
        }
      }
    }

    guideContentMeta: allContentfulGuideContent {
      pillars: distinct(field: pillar)
    }

    allContentfulGuideContent(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          isPremium
          isFeatured
          isPicked
          pillar
          publishDate
          excerpt
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77777777778)
          }
          attachedFile {
            file {
              url
            }
          }
          buttonDestination
        }
      }
    }
  }
`

